import { AppComponent, RsNumberSelectionComponent } from './components';
import { AppRoutingModule } from './routes/app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModules } from '@shared/shared.module';
import { environment } from '@environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { EffectsModule } from '@ngrx/effects';
import { coreReducer, metaReducers } from './states/core.reducer';
import { FebiacModelEffects } from './states/febiac-model/febiac-model.effects';
import { CoreEffects } from './states/core.effects';
import { errorHandlerProviders } from './error-handlers';
import { coreInterceptorProviders } from './http-interceptors';
import { RsCoreModule, UserInfoCookieAppName } from '@lib/rs-ngx';
import { MatIconRegistry } from '@angular/material/icon';

// Registering some locals to be able to used it for numbers and dates
registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

@NgModule({ declarations: [AppComponent, NotFoundComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RsNumberSelectionComponent,
    SharedModules,
    EffectsModule.forRoot([
      FebiacModelEffects,
      CoreEffects
    ]),
    StoreModule.forRoot(coreReducer, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, // Retains last 25 state
      logOnly: environment.production // Restrict extension to log-only mode
      ,
      connectInZone: true }),
    RsCoreModule.forRoot({
      appName: UserInfoCookieAppName.MRT,
      environment
    })
  ],
  providers: [
    errorHandlerProviders,
    coreInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ] })
export class CoreModule {
  private readonly customIconsFolder: string;
  private readonly customIconsArray: string[];

  public constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer
  ) {
    /** Custom icons folder path */
    this.customIconsFolder = 'assets/img/custom-mat-icons/';

    /** Custom icons file name list */
    this.customIconsArray = [
      'download',
      'download-outline',
      'file-image',
      'file-outline',
      'funnel',
      'file-pdf-outline',
      'speak-no-evil-monkey',
      'emoticon-dead',
      'file-excel',
      'arrow-right-alt',
      '4seasons-22',
      'maintenance-22',
      'repair-22',
      'replacement vehicle-22',
      'summer-22',
      'winter-22',
      'tire-22',
      'assistance-22',
      'icon-all-weather',
    ];

    /** Add custom icons to mat icon registry */
    this.customIconsArray.forEach((iconName): void => {
      this.matIconRegistry.addSvgIcon(
        `${iconName}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.customIconsFolder}${iconName}.svg`
        )
      );
    });
  }
}
