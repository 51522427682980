/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RsNumberSelectionComponent } from '../components';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { isLeasingCompanyUserGuard } from '@shared/guards/leasing-company/leasing-company.guard';
import { isAdminGuard } from '@shared/guards/admin/admin.guard';
import { adminRoutes } from '@app/admin/admin.routes';
import { leasingCompanyRoutes } from '@app/leasing-company/leasing-company.routes';
import { supplierRoutes } from '@app/supplier/supplier.routes';
import { isSupplierUserGuard } from '@shared/guards/supplier/supplier.guard';
import { oldUiGuard } from '@shared/guards/old-ui/old-ui.guard';
import { isFeatureEnabled } from '@core/feature-flag/feature-flag.guard';

const routes: Routes = [
  {
    pathMatch: 'full',
    path: '',
    component: RsNumberSelectionComponent,
  },
  {
    pathMatch: 'full',
    path: 'rs-number',
    component: RsNumberSelectionComponent,
  },
  {
    path: 'admin',
    children: adminRoutes,
    canActivate: [isFeatureEnabled('new-ui.scope.admin', '/old-ui'), isAdminGuard()],
  },
  {
    path: 'leasing-company',
    children: leasingCompanyRoutes,
    canActivate: [isFeatureEnabled('new-ui.scope.leasing-company', '/old-ui'), isLeasingCompanyUserGuard()],
  },
  {
    path: 'supplier',
    children: supplierRoutes,
    canActivate: [isFeatureEnabled('new-ui.scope.supplier', '/old-ui'), isSupplierUserGuard()]
  },
  {
    path: 'old-ui',
    canActivate: [oldUiGuard()],
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64],
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
