import { createAction, props } from '@ngrx/store';
import {
  LeasingCompanyThresholdParameters
} from '@app/leasing-company/leasing-company-thresholds/shared/models/leasing-company-threshold-parameters';

export const updateLeasingCompanyThresholdsOverviewFilter = createAction(
  '[LeasingCompanyThresholdsOverview] Load LeasingCompanyThresholdsOverview',
  props<{ params: LeasingCompanyThresholdParameters }>()
);

export const updateLeasingCompanyThresholdsOverviewPaginator = createAction(
  '[Overview] Update Leasing Company Thresholds Paginator',
  props<{
        resultsPerPage: number;
        page: number;
    }>()
);


export const updateLeasingCompanyThresholdsOverviewSort = createAction(
  '[Overview] Update Leasing Company Thresholds Sort',
  props<{
        sortDirection: string;
        sortBy: string;
    }>()
);
