import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, shareReplay } from 'rxjs';
import { FeatureFlag } from '@core/feature-flag/feature-flag.model';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { UserService } from '@core/services/user.service';
import { environment } from '@environments/environment';
import { User } from '@core/models/user';
import { nonNull } from '@lib/utils/custom-rxjs-operators/rsNonNull';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private readonly flags$: Observable<FeatureFlag[]>;

  constructor(private readonly httpClient: HttpClient, userService: UserService) {
    // We want to avoid the value in shareReplay to be returned when the user changes and flags are being loaded from the API
    // To achieve this, we directly emit a "null" value when user changes, it is kept as value in the shareReplay buffer but
    //   not emitted thanks to the filter.
    // Therefore, no values are emitted while flags are being refreshed.
    this.flags$ = userService.getCurrentUser().asObservable().pipe(
      switchMap(user => this.getFeatureFlagsForUser(user).pipe(startWith(null))),
      shareReplay({ bufferSize: 1, refCount: false }),
      filter(nonNull),
    );
  }

  public readonly getFeatureFlags = (): Observable<FeatureFlag[]> => {
    return this.flags$;
  };

  private getFeatureFlagsForUser(user: User | null): Observable<FeatureFlag[]> {
    return user ?
      this.httpClient.get<FeatureFlag[]>(`${environment.apiUrl}/shared/feature-flag`) :
      of([]);
  }
}
