import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesShort, RsDynamicMatAutocomplete } from '@lib/rs-ngx';
import { UntypedFormGroup } from '@angular/forms';
import { CodeDescription } from '@shared/models/code-description';
import { ActivitiesService } from '../../services/activities/activities.service';
import { finalize, map } from 'rxjs/operators';
import { isEmpty, uniqBy } from 'lodash';

interface RequestParams {
  _q?: string;
  _userLanguage: LanguagesShort;
}

/** @Documentation For usage and documentation please refer to RsDynamicMatAutocompleteComponent */
@Component({
  selector: 'mrt-select-activity-definition-autocomplete[formGroup][nameOfFormControl][optionsEndpoint]',
  templateUrl: './select-activity-definition-autocomplete.component.html'
})
export class SelectActivityDefinitionAutocompleteComponent implements RsDynamicMatAutocomplete {
  @Input() public nameOfFormControl!: string;
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public rsDma_placeholder = 'SEARCH_AN_ACTIVITY_ON_ACTIVITY_CODE_OR_DESCRIPTION';
  @Input() public rsDma_required = false;
  @Input() public bannedValue?: CodeDescription;
  @Input() public optionsEndpoint!: 'defectTaskWarranty' | 'activityDefinitions';
  public rsDma_isSearchingOptions = false;
  public rsDma_options: CodeDescription[] = [];

  constructor(
      private readonly translateService: TranslateService,
      private readonly activityDefinitionService: ActivitiesService,
  ) {
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  public rsDma_OnFilterChange(filterValue: string): void {
    const minLength = isNaN(parseInt(filterValue)) ? 3 : 2;

    const params: RequestParams = {
      _q: filterValue,
      _userLanguage: this.currentLang as LanguagesShort,
    };

    if (filterValue?.length >= minLength) {
      this.rsDma_isSearchingOptions = true;

      const datasource = this.optionsEndpoint === 'defectTaskWarranty' ? 'getActivityDefectTaskWarranty' : 'getActivitiesDefinition';

      this[datasource](params);
    }
  }

  public rsDma_displayWithFunction(codeDescription: CodeDescription): string {
    return isEmpty(codeDescription)
      ? ''
      : `${codeDescription.code} - ${codeDescription.description[this.currentLang]}`;
  }

  private getActivitiesDefinition(params: RequestParams): void {
    this.activityDefinitionService
      .getActivityDefinitions(params)
      .pipe(
        finalize(() => {
          this.rsDma_isSearchingOptions = false;
        }),
        map(data => data.results),
      )
      .subscribe((activityDefinitions) => {
        this.rsDma_options = activityDefinitions.map((activityDefinition) => {
          return {
            code: activityDefinition.code,
            description: activityDefinition.description
          };
        });
      });
  }

  private getActivityDefectTaskWarranty(params: RequestParams): void {
    this.activityDefinitionService
      .getActivityDefectTaskWarranties(params)
      .pipe(
        map(data => data.results),
        map((activityTaskWarranty) => {
          return activityTaskWarranty.map((activityDefectTaskWarranty) => {
            return activityDefectTaskWarranty.activityDefinition;
          });
        }),
        finalize(() => {
          this.rsDma_isSearchingOptions = false;
        })
      )
      .subscribe((activityDefinitions) => {
        const filteredActivityDefinitions = uniqBy(activityDefinitions, (activity) => activity.code);

        this.rsDma_options = this.bannedValue ? this.filterBannedValue(filteredActivityDefinitions) : filteredActivityDefinitions;
      });

  }

  private filterBannedValue(activities: CodeDescription[]): CodeDescription[] {
    return activities.filter((activity) => {
      return activity?.code !== this.bannedValue?.code;
    });
  }
}
