<div mat-dialog-content>
  {{ data.text }}
</div>

<div mat-dialog-actions>
  <button
    (click)="cancel()"
    mat-button
    type="button"
    data-testid="warning-dialog-cancel-button"
    class="rs-btn--secondary"
  >
    {{ 'CANCEL' | translate }}
  </button>

  <button
    (click)="confirm()"
    class='rs-btn--primary'
    mat-button
    data-testid="warning-dialog-confirm-button"
  >
    {{ data.confirmLabel | translate }}
  </button>
</div>