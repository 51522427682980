import { Route } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  overviewWorkOrderFeatureKey,
  workOrderInitialState,
  workOrderMetaReducers,
  workOrderReducer
} from '@app/supplier/work-order/overview/state/overview.reducer';
import { allowNavigationGuard } from '@shared/guards/allow-navigation/allow-navigation.guard';

export const supplierRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'work-orders',
  },
  {
    path: 'work-orders',
    children: [
      {
        path: '',
        loadComponent: () => import('@app/supplier/work-order/overview/overview.component').then((component) => component.OverviewComponent),
        providers: [
          importProvidersFrom(
            StoreModule
              .forFeature(
                overviewWorkOrderFeatureKey,
                workOrderReducer,
                {
                  initialState: workOrderInitialState(),
                  metaReducers: workOrderMetaReducers,
                }
              )
          )
        ],
      },
      {
        path: 'add',
        loadComponent: () => import('@app/supplier/work-order/add/add.component').then(component => component.AddComponent),
      },
      {
        path: ':id',
        children: [
          {
            path: 'activities',
            loadComponent: () => import('@app/supplier/work-order/details/activities/activities.component'),
          },
          {
            path: 'details',
            loadComponent: () => import('@app/supplier/work-order/details/details/details.component'),
          },
          {
            path: 'documents',
            loadComponent: () => import('@app/supplier/work-order/details/documents/documents.component').then(component => component.DocumentsComponent),
            canDeactivate: [allowNavigationGuard()]
          },
          {
            path: '',
            redirectTo: 'activities',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },

];
