<div
  [id]="stepTitle"
  class="rs-card"
>
  <div
    [ngClass]="isBoxOpened ? 'rs-divider mb-4 pb-4' : ''"
    class="d-flex align-items-center"
  >
    <div
      [ngClass]="isBoxOpened ? 'txt-circle-fill' : 'txt-circle-outline'"
      class="me-3"
    >
      {{ stepNumber }}
    </div>

    <h3>{{ stepTitle }}</h3>

    <mat-slide-toggle
      (change)="onSubmitToggleActive($event)"
      *ngIf="displayActiveBtn"
      [checked]="true"
      class="ms-auto"
      id="isActive"
    >
      <span class="font-weight-light">
        {{ 'ACTIVE' | translate }}
      </span>
    </mat-slide-toggle>
  </div>

  <ng-content *ngIf="isBoxOpened"></ng-content>
</div>