import { Action, createReducer, on } from '@ngrx/store';
import {
  LeasingCompanyPartsReductionSummary
} from '@shared/models/leasing-company-parts-reduction/leasing-company-parts-reduction-summary';
import {
  SupplierGroupSummary
} from '@shared/models/supplier-groups/supplier-group-summary';
import {
  updateActivityGroup,
  updatePartsReductionPercentage,
  updateSupplierGroup
} from './activity-group-reduction-add.actions';
import { CodeDescription } from '@shared/models/code-description';

export const activityGroupReductionAddFeatureKey = 'activityGroupReductionAdd';
export interface ActivityGroupReductionAddState {
  activityGroup: CodeDescription | null;
  supplierGroup: SupplierGroupSummary | null;
  leasingCompanyPartsReduction: LeasingCompanyPartsReductionSummary
}

export const initialState: ActivityGroupReductionAddState = {
  activityGroup: null,
  supplierGroup: null,
  leasingCompanyPartsReduction: {} as LeasingCompanyPartsReductionSummary
};

export const _reducer = createReducer(
  initialState,

  on(updateActivityGroup, (state, { activityGroup }) => {
    return { ...state,
      leasingCompanyPartsReduction: {
        ...state.leasingCompanyPartsReduction,
        activityGroup
      } }
  }),
  on(updateSupplierGroup, (state, { supplierGroup }) => {
    return { ...state,
      leasingCompanyPartsReduction: {
        ...state.leasingCompanyPartsReduction,
        supplierGroup
      } }
  }),
  on(updatePartsReductionPercentage, (state, { partsReductionPercentage }) => {
    return { ...state,
      leasingCompanyPartsReduction: {
        ...state.leasingCompanyPartsReduction,
        partsReductionPercentage,
      } }
  })
);

export function activityGroupReductionAddReducer(
    state: ActivityGroupReductionAddState,
    action: Action,
): ActivityGroupReductionAddState {
  return _reducer(state, action);
}

