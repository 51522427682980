import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { isLeasingCompanyUserGuard } from '@shared/guards/leasing-company/leasing-company.guard';
import { StoreModule } from '@ngrx/store';
import {
  overviewVehicleTypeFeatureKey,
  vehicleTypeInitialState,
  vehicleTypeMetaReducers,
  vehicleTypeReducer
} from '@app/leasing-company/vehicle-types/overview/state/overview.reducer';
import {
  disapprovalReasonInitialState,
  disapprovalReasonMetaReducers,
  disapprovalReasonReducer,
  overviewDisapprovalReasonFeatureKey
} from '@app/leasing-company/disapproval-reason/overview/overview/state/overview.reducer';
import {
  overviewPreferredTyreBrandFeatureKey,
  preferredTyreBrandInitialState,
  preferredTyreBrandReducer,
  prefTyreBrandMetaReducers
} from '@app/leasing-company/preferred-tyre-brand/overview/state/overview.reducer';
import {
  overviewRentalClassFeatureKey,
  rentalClassInitialState,
  rentalClassMetaReducers,
  rentalClassReducer
} from '@app/leasing-company/rental-classes/overview/state/overview.reducer';
import {
  brandThresholdInitialState,
  brandThresholdMetaReducers,
  brandThresholdReducer,
  overviewBrandThresholdFeatureKey
} from '@app/leasing-company/brand-threshold/overview/state/overview.reducer';
import {
  overviewTyreMaintenanceFeatureKey,
  tyreMaintenanceInitialState,
  tyreMaintenanceMetaReducers,
  tyreMaintenanceReducer
} from '@app/leasing-company/tyre-maintenance/overview/state/overview.reducer';
import {
  overviewTyreBrandDiscountFeatureKey,
  tyreBrandDiscountInitialState,
  tyreBrandDiscountMetaReducers,
  tyreBrandDiscountReducer
} from '@app/leasing-company/tyre-brand-discount/general/overview/state/overview.reducer';
import {
  overviewTyreBrandDiscountSupplierGroupFeatureKey,
  tyreBrandDiscountSupplierGroupInitialState,
  tyreBrandDiscountSupplierGroupMetaReducers,
  tyreBrandDiscountSupplierGroupReducer
} from '@app/leasing-company/tyre-brand-discount/supplier-groups/overview/state/overview.reducer';
import {
  leasingContractGroupInitialState,
  leasingContractGroupMetaReducers,
  leasingContractGroupReducer,
  overviewLeasingContractGroupFeatureKey
} from '@app/leasing-company/leasing-contract-group/overview/state/overview.reducer';
import { isFeatureEnabled } from '@core/feature-flag/feature-flag.guard';
import {
  activityGroupReductionAddFeatureKey,
  activityGroupReductionAddReducer
} from '@app/leasing-company/activity-group-reductions/add/state/activity-group-reduction-add.reducer';
import {
  customCommentsMetaReducers,
  customCommentsOverviewFeatureKey,
  customCommentsOverviewInitialState,
  customCommentsOverviewReducer
} from '@app/leasing-company/custom-comments/overview/state/custom-comments-overview.reducer';
import {
  leasingCompanyThresholdInitialState,
  leasingCompanyThresholdOverviewFeatureKey,
  leasingCompanyThresholdOverviewReducer
} from '@app/leasing-company/leasing-company-thresholds/overview/state/overview.reducer';
import {
  linkedActivitiesOverviewFilterFeatureKey,
  linkedActivitiesOverviewFiltersReducer
} from '@app/leasing-company/maintenance-intervals/linked-activities/overview/components/state/linked-activities-overview-filters.reducer';
import { EffectsModule } from '@ngrx/effects';
import {
  LinkedActivitiesAddEffects
} from '@app/leasing-company/maintenance-intervals/linked-activities/add/state/linked-activities-add.effects';
import {
  DoubleMaintenanceAddEffects
} from '@app/leasing-company/maintenance-intervals/double-maintenance/add/state/double-maintenance-add.effects';
import {
  disapprovalReasonThresholdInitialState,
  disapprovalReasonThresholdMetaReducers,
  disapprovalReasonThresholdReducer,
  overviewDisapprovalReasonThresholdFeatureKey
} from '@app/leasing-company/disapproval-reason-threshold/overview/state/overview.reducer';

export const leasingCompanyRoutes: Routes =
  [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'work-orders',
    },
    {
      path: 'data-mapping',
      loadChildren: () =>
        import('@app/leasing-company/data-mapping/data-mapping.module').then(
          (module) => module.DataMappingModule
        ),
      canActivate: [isLeasingCompanyUserGuard({ roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'] }), isFeatureEnabled('leasing-company.data-mapping')],
    },
    {
      path: 'data-import',
      loadChildren: () => import('@app/leasing-company/data-import/data-import.module').then(
        (module) => module.DataImportModule
      ),
      canActivate: [
        isLeasingCompanyUserGuard({ roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'] }),
        isFeatureEnabled('leasing-company.data-import')
      ],
    },
    {
      path: 'user-authorizations',
      loadChildren: () =>
        import('@app/leasing-company/user-authorizations/user-authorizations.module').then(
          (module) => module.UserAuthorizationsModule
        )
    },
    {
      path: 'administration',
      loadComponent: () => import('@app/leasing-company/administration/administration.component').then(component => component.AdministrationComponent),
      children: [
        {
          path: 'overview',
          loadComponent: () => import('@app/leasing-company/administration/administration-overview/administration-overview.component').then(component => component.AdministrationOverviewComponent)
        },
        {
          path: 'settings',
          loadComponent: () => import('@app/leasing-company/administration/settings/settings.component').then(component => component.SettingsComponent)
        },
        {
          path: 'settings/edit',
          loadComponent: () => import('@app/leasing-company/administration/settings/components/edit/settings-edit.component').then(component => component.SettingsEditComponent)
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'overview',
        }
      ]
    },
    {
      path: 'supplier-groups',
      children: [
        {
          path: '',
          loadChildren: () => import('@app/leasing-company/supplier-groups/overview/overview.module').then((module) => module.OverviewModule),
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/supplier-groups/add/add.component').then((component) => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
              urlToRedirect: '/leasing-company/supplier-groups',
            })
          ],
        },
        {
          path: ':supplierGroupId',
          loadComponent: () => import('@app/leasing-company/supplier-groups/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':supplierGroupId/edit',
          loadComponent: () => import('@app/leasing-company/supplier-groups/edit/edit.component').then(component => component.EditComponent),
        },
      ]
    },
    {
      path: 'work-orders',
      loadChildren: () =>
        import('@app/leasing-company/work-orders/work-orders.module')
          .then(
            (module) => module.WorkOrdersModule
          )
    },
    {
      path: 'suppliers',
      children: [
        {
          path: '',
          loadChildren: () => import('@app/leasing-company/suppliers/overview/overview.module').then((module) => module.OverviewModule),
        },
        {
          path: ':supplierId',
          loadComponent: () => import('@app/leasing-company/suppliers/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':supplierId/edit',
          loadComponent: () => import('@app/leasing-company/suppliers/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ]
        },
      ]
    },
    {
      path: 'maintenance-intervals',
      loadComponent: () => import('@app/leasing-company/maintenance-intervals/main-intervals.component').then(component => component.MainIntervalsComponent),
      children: [
        {
          path: '',
          loadChildren: () => import('@app/leasing-company/maintenance-intervals/double-maintenance/overview/overview.module').then(component => component.OverviewModule),
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/maintenance-intervals/double-maintenance/add/add.component').then(component => component.AddComponent),
          providers: [
            importProvidersFrom(
              EffectsModule.forFeature([DoubleMaintenanceAddEffects])
            )
          ],
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
              urlToRedirect: '/leasing-company/maintenance-intervals',
            })
          ],
        },
        {
          path: 'linked-activities',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/leasing-company/maintenance-intervals/linked-activities/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule.forFeature(linkedActivitiesOverviewFilterFeatureKey, linkedActivitiesOverviewFiltersReducer),
                )
              ]
            },
            {
              path: 'add',
              loadComponent: () => import('@app/leasing-company/maintenance-intervals/linked-activities/add/add.component').then(component => component.AddComponent),
              providers: [
                importProvidersFrom(
                  EffectsModule.forFeature([LinkedActivitiesAddEffects])
                )
              ],
              canActivate: [
                isLeasingCompanyUserGuard({
                  roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
                  urlToRedirect: '/leasing-company/maintenance-intervals',
                })
              ],
            },
          ]
        },
        {
          path: ':doubleMaintenanceId',
          loadComponent: () => import('@app/leasing-company/maintenance-intervals/double-maintenance/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':doubleMaintenanceId/edit',
          loadComponent: () => import('@app/leasing-company/maintenance-intervals/double-maintenance/edit/edit.component').then(component => component.EditComponent),
        },
      ],
    },
    {
      path: 'packs',
      canActivate: [isFeatureEnabled('leasing-company.packs')],
      loadChildren: () => import('@app/leasing-company/packs/packs.module').then((module) => module.PacksModule)
    },
    {
      path: 'leasing-contracts',
      loadChildren: () =>
        import('@app/leasing-company/leasing-contracts/leasing-contracts.module')
          .then(
            (module) => module.LeasingContractsModule
          )
    },
    {
      path: 'leasing-company-thresholds',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/leasing-company-thresholds/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  leasingCompanyThresholdOverviewFeatureKey,
                  leasingCompanyThresholdOverviewReducer,
                  {
                    initialState: leasingCompanyThresholdInitialState
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/leasing-company-thresholds/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        },
        {
          path: ':leasingCompanyThresholdId',
          loadComponent: () => import('@app/leasing-company/leasing-company-thresholds/detail/detail.component').then(component => component.DetailComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        },
        {
          path: ':leasingCompanyThresholdId/edit',
          loadComponent: () => import('@app/leasing-company/leasing-company-thresholds/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        }
      ]
    },
    {
      path: 'activity-group-reductions',
      canActivate: [isFeatureEnabled('leasing-company.activity-group-reductions')],
      children: [
        {
          path: '',
          loadChildren: () => import('@app/leasing-company/activity-group-reductions/overview/overview.module').then((module) => module.OverviewModule),
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/activity-group-reductions/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
              urlToRedirect: '/leasing-company/activity-group-reductions',
            })
          ],
          providers: [
            importProvidersFrom(
              StoreModule.forFeature(
                activityGroupReductionAddFeatureKey,
                activityGroupReductionAddReducer
              ),
            )
          ]
        },
        {
          path: ':activityGroupReductionId',
          loadComponent: () => import('@app/leasing-company/activity-group-reductions/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':activityGroupReductionId/edit',
          loadComponent: () => import('@app/leasing-company/activity-group-reductions/edit/edit.component').then(component => component.EditComponent),
        }
      ]
    },
    {
      path: 'vehicle-types',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/vehicle-types/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewVehicleTypeFeatureKey,
                  vehicleTypeReducer,
                  {
                    initialState: vehicleTypeInitialState,
                    metaReducers: vehicleTypeMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/vehicle-types/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        },
        {
          path: ':id',
          loadComponent: () => import('@app/leasing-company/vehicle-types/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':id/edit',
          loadComponent: () => import('@app/leasing-company/vehicle-types/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
              urlToRedirect: '/leasing-company/work-orders',
            }),
          ]
        }
      ]
    },
    {
      path: 'disapproval-reasons',
      loadComponent: () => import('@app/leasing-company/disapproval-reason/disapproval-reason.component').then(component => component.DisapprovalReasonComponent),
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/disapproval-reason/overview/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewDisapprovalReasonFeatureKey,
                  disapprovalReasonReducer,
                  {
                    initialState: disapprovalReasonInitialState,
                    metaReducers: disapprovalReasonMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'activity-exceptions',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/leasing-company/disapproval-reason/activity-exceptions/overview/overview.component').then(component => component.OverviewComponent),
            }
          ]
        },
        {
          path: ':disapprovalReasonId',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/leasing-company/disapproval-reason/overview/details/details.component').then(component => component.DetailsComponent),
            },
            {
              path: 'edit',
              loadComponent: () => import('@app/leasing-company/disapproval-reason/overview/edit/edit.component').then(component => component.EditComponent),
              canActivate: [
                isLeasingCompanyUserGuard({
                  roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
                }),
              ],
            },
          ]
        },
        {
          path: '**',
          redirectTo: 'disapproval-reasons'
        }
      ],
    },
    {
      path: 'disapproval-reason-thresholds',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/disapproval-reason-threshold/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewDisapprovalReasonThresholdFeatureKey,
                  disapprovalReasonThresholdReducer,
                  {
                    initialState: disapprovalReasonThresholdInitialState(),
                    metaReducers: disapprovalReasonThresholdMetaReducers,
                  }
                )
            )
          ],
        },
      ],
    },
    {
      path: 'preferred-tyre-brands',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/preferred-tyre-brand/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewPreferredTyreBrandFeatureKey,
                  preferredTyreBrandReducer,
                  {
                    initialState: preferredTyreBrandInitialState,
                    metaReducers: prefTyreBrandMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/preferred-tyre-brand/add/add.component').then(component => component.AddComponent),
        },
        {
          path: ':preferredTyreBrandId',
          loadComponent: () => import('@app/leasing-company/preferred-tyre-brand/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':preferredTyreBrandId/edit',
          loadComponent: () => import('@app/leasing-company/preferred-tyre-brand/edit/edit.component').then(component => component.EditComponent),
        },
      ]
    },
    {
      path: 'custom-comments',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/custom-comments/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  customCommentsOverviewFeatureKey,
                  customCommentsOverviewReducer,
                  {
                    initialState: customCommentsOverviewInitialState,
                    metaReducers: customCommentsMetaReducers
                  }
                )
            )
          ]
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/custom-comments/add/add.component').then(component => component.AddComponent),
        },
        {
          path: ':customCommentId',
          loadComponent: () => import('@app/leasing-company/custom-comments/detail/detail.component').then(component => component.DetailComponent),
        },
        {
          path: ':customCommentId/edit',
          loadComponent: () => import('@app/leasing-company/custom-comments/edit/edit.component').then(component => component.EditComponent),
        },
      ],
    },
    {
      path: 'rental-classes',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/rental-classes/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewRentalClassFeatureKey,
                  rentalClassReducer,
                  {
                    initialState: rentalClassInitialState,
                    metaReducers: rentalClassMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: ':rentalClassCode',
          loadComponent: () => import('@app/leasing-company/rental-classes/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':rentalClassCode/edit',
          loadComponent: () => import('@app/leasing-company/rental-classes/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ],
        },
      ],
    },
    {
      path: 'brand-thresholds',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/brand-threshold/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewBrandThresholdFeatureKey,
                  brandThresholdReducer,
                  {
                    initialState: brandThresholdInitialState,
                    metaReducers: brandThresholdMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/brand-threshold/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        },
        {
          path: ':brandThresholdId',
          loadComponent: () => import('@app/leasing-company/brand-threshold/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':brandThresholdId/edit',
          loadComponent: () => import('@app/leasing-company/brand-threshold/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ],
        },
      ]
    },
    {
      path: 'tyre-maintenances',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/tyre-maintenance/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewTyreMaintenanceFeatureKey,
                  tyreMaintenanceReducer,
                  {
                    initialState: tyreMaintenanceInitialState,
                    metaReducers: tyreMaintenanceMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/tyre-maintenance/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ],
        },
        {
          path: ':tyreMaintenanceId',
          loadComponent: () => import('@app/leasing-company/tyre-maintenance/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':tyreMaintenanceId/edit',
          loadComponent: () => import('@app/leasing-company/tyre-maintenance/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ]
        }
      ]
    },
    {
      path: 'tyre-brand-discounts',
      loadComponent: () => import('@app/leasing-company/tyre-brand-discount/tyre-brand-discount-parent-overview.component')
        .then(component => component.TyreBrandDiscountParentOverviewComponent),
      children: [
        {
          path: 'general',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/leasing-company/tyre-brand-discount/general/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule
                    .forFeature(
                      overviewTyreBrandDiscountFeatureKey,
                      tyreBrandDiscountReducer,
                      {
                        initialState: tyreBrandDiscountInitialState,
                        metaReducers: tyreBrandDiscountMetaReducers,
                      }
                    )
                )
              ],
            },
            {
              path: 'add',
              loadComponent: () => import('@app/leasing-company/tyre-brand-discount/general/add/add.component').then(component => component.AddComponent),
              canActivate: [
                isLeasingCompanyUserGuard({
                  roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
                }),
              ],
            }
          ]
        },
        {
          path: 'supplier-group',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/leasing-company/tyre-brand-discount/supplier-groups/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule
                    .forFeature(
                      overviewTyreBrandDiscountSupplierGroupFeatureKey,
                      tyreBrandDiscountSupplierGroupReducer,
                      {
                        initialState: tyreBrandDiscountSupplierGroupInitialState,
                        metaReducers: tyreBrandDiscountSupplierGroupMetaReducers,
                      }
                    )
                )
              ],
            },
            {
              path: 'add',
              loadComponent: () => import('@app/leasing-company/tyre-brand-discount/supplier-groups/add/add.component').then(component => component.AddComponent),
              canActivate: [
                isLeasingCompanyUserGuard({
                  roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
                })
              ],
            },
          ]
        },
        {
          path: '**',
          redirectTo: 'general'
        }
      ]
    },
    {
      path: 'leasing-contract-groups',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/leasing-contract-group/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewLeasingContractGroupFeatureKey,
                  leasingContractGroupReducer,
                  {
                    initialState: leasingContractGroupInitialState,
                    metaReducers: leasingContractGroupMetaReducers,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/leasing-company/leasing-contract-group/add/add.component').then(component => component.AddComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            })
          ],
        },
        {
          path: ':leasingContractGroupId',
          loadComponent: () => import('@app/leasing-company/leasing-contract-group/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':leasingContractGroupId/edit',
          loadComponent: () => import('@app/leasing-company/leasing-contract-group/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ]
        }
      ]
    },
    {
      path: 'cost-centre-configuration',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/leasing-company/cost-centre-configuration/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: 'edit',
          loadComponent: () => import('@app/leasing-company/cost-centre-configuration/edit/edit.component').then(component => component.EditComponent),
          canActivate: [
            isLeasingCompanyUserGuard({
              roles: ['LEASING_COMPANY_MANAGER', 'LEASING_COMPANY_WITH_AUTHORIZATION'],
            }),
          ]
        }
      ]
    },
  ];
