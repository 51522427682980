import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgForOf } from '@angular/common';
import { Observable, share } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-rs-number-selection',
  templateUrl: './rs-number-selection.component.html',
  styleUrls: ['./rs-number-selection.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    NgForOf,
    AsyncPipe
  ],
})
export class RsNumberSelectionComponent implements OnInit {

  @Input()
  protected rsUserNumber?: string;
  protected users$!: Observable<User[]>;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) {
  }

  public selectRsUser(user: User): void {
    this.userService.setCurrentUser(user);
    this.router.navigateByUrl(this.userService.getDefaultRedirectURL());
  }

  public ngOnInit(): void {
    this.users$ = this.userService.getAvailableUsers().pipe(
      share()
    );

    const currentUser = this.userService.getCurrentUser();

    if (this.rsUserNumber) {
      this.users$.pipe(
        map(users => users.find(user => user.loginString === this.rsUserNumber)),
        filter(user => !!user),
      ).subscribe(user => this.selectRsUser(user));
    } else if (currentUser.getValue()) {
      this.router.navigateByUrl(this.userService.getDefaultRedirectURL());
    }
  }
}
