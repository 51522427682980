<div [formGroup]='formGroup'>
  <rs-dynamic-mat-autocomplete
    (onInputFilterOptionsChange)='rsDma_OnFilterChange($event)'
    [displayWithFunction]='rsDma_displayWithFunction.bind(this)'
    [formControlName]="nameOfFormControl"
    [isSearchingOptions]='rsDma_isSearchingOptions'
    [options]='rsDma_options'
    [placeholder]='rsDma_placeholder'
    [required]="rsDma_required"
  />
</div>