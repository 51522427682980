import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserType } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly currentUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    JSON.parse(localStorage.getItem('currentUser') as string) as User | null
  );

  constructor(private readonly http: HttpClient) {
  }

  public getAvailableUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiUrl + '/auth/user')
      .pipe(
        map(users => users.map(user => omit(user, 'email')))
      );
  }

  public setCurrentUser(user: User | null): void {
    this.currentUser$.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public readonly getCurrentUser = (): BehaviorSubject<User | null> => {
    return this.currentUser$;
  }

  public getUserRole(): string {
    // eslint-disable-next-line rxjs/no-subject-value
    const userRole = this.currentUser$.getValue()?.userRole;
    return userRole ? userRole : '';
  }

  public isLeasingCompanyUser(): boolean {
    switch (this.getUserRole()) {
      case 'LEASING_COMPANY':
      case 'LEASING_COMPANY_WITH_AUTHORIZATION':
      case 'LEASING_COMPANY_MANAGER':
        return true;
      default:
        return false;
    }
  }

  public isAdminUser(): boolean {
    return this.getUserRole() === 'ADMIN';
  }

  public isSupplierUser(): boolean {
    return this.getUserRole() === 'SUPPLIER' || this.getUserRole() === 'DMS';
  }

  public hasAccessToWorkOrders(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToAdmin(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToAdminAdministration(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToAdminAuthorizations(): boolean {
    return this.getUserRole() === 'LEASING_COMPANY_MANAGER';
  }

  public hasAccessToAdminDataImport(): boolean {
    return this.getUserRole() === 'LEASING_COMPANY_MANAGER';
  }

  public hasAccessToAdminDataMapping(): boolean {
    switch (this.getUserRole()) {
      case 'LEASING_COMPANY_WITH_AUTHORIZATION':
      case 'LEASING_COMPANY_MANAGER':
        return true;
      default:
        return false;
    }
  }

  public hasWriteAccessToLeasingCompanyData(): boolean {
    switch (this.getUserRole()) {
      case 'LEASING_COMPANY_WITH_AUTHORIZATION':
      case 'LEASING_COMPANY_MANAGER':
        return true;
      default:
        return false;
    }
  }

  public hasAccessToManagement(): boolean {
    return this.isLeasingCompanyUser() || this.isAdminUser();
  }

  public hasAccessToManagementLeasingContracts(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementSupplierGroups(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementSuppliers(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementLeasingCompanyThresholds(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementMainIntervals(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementActivityGroupReductions(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementPacks(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementActivities(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementActivityGroups(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementVehicleTypes(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementDisapprovalReasons(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementRentalClasses(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementWarranties(): boolean {
    return this.isAdminUser();
  }

  public hasAccessToManagementCustomComments(): boolean {
    return this.isLeasingCompanyUser();
  }

  public hasAccessToManagementFleetOwners(): boolean {
    return this.isAdminUser();
  }

  public hasEditAccessToManagementCustomComments(): boolean {
    switch (this.getUserRole()) {
      case 'LEASING_COMPANY_WITH_AUTHORIZATION':
      case 'LEASING_COMPANY_MANAGER':
        return true;
      default:
        return false;
    }
  }

  public getDefaultRedirectURL(): string {
    if (this.isLeasingCompanyUser()) {
      return '/leasing-company';
    }
    if (this.isAdminUser()) {
      return '/admin';
    }
    if (this.isSupplierUser()) {
      return '/supplier';
    }

    return '/rs-number';
  }

  public getUserType(): UserType | null {
    if (this.isLeasingCompanyUser()) {
      return 'LEASING_COMPANY';
    }
    if (this.isAdminUser()) {
      return 'ADMIN';
    }
    if (this.isSupplierUser()) {
      return 'SUPPLIER';
    }

    return null;
  }
}
