import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ui-renta-rs-box[stepNumber]',
  templateUrl: './rs-box.component.html',
  styleUrls: ['./rs-box.component.scss'],
})
export class RsBoxComponent {
  @Input() public isBoxOpened?: boolean;
  @Input() public stepTitle?: string;
  @Input() public stepNumber!: number;
  @Input() public displayActiveBtn = false;

  @Output()
  public submitToggleActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onSubmitToggleActive(event: MatSlideToggleChange): void {
    this.submitToggleActive.emit(event.checked);
  }
}
