import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-YYYY',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'ui-renta-year-month-picker',
  templateUrl: './year-month-picker.component.html',
  styleUrls: ['./year-month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => YearMonthPickerComponent),
      multi: true,
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearMonthPickerComponent implements ControlValueAccessor {
  @Input() public label?: string;
  @Input() public disabled = false;
  public value?: moment.Moment;
  private year?: string;
  private month?: string;

  public writeValue(value: string): void {
    this.value = moment(value, 'YYYY-MM');
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public clearField(): void {
    this.value = undefined;
    this.onChange('');
    this.onTouched();
    this.writeValue('');
  }

  public chosenYearHandler(normalizedYear: moment.Moment): void {
    this.year = normalizedYear.format('YYYY');
  }

  public chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ): void {
    this.month = normalizedMonth.format('MM');

    if (this.year && this.month) {
      const newDate = `${this.year}-${this.month}`;
      this.onChange(newDate);
      this.onTouched();
      this.writeValue(newDate);
    }

    datepicker.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: Function = () => {
    // on changed
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: Function = () => {
    // on touched
  };
}
