import { createAction, props } from '@ngrx/store';
import { ActivityGroupParameters } from '@shared/models/activity-definition-parameters';

export const updateActivitiesDefinitionOverviewFilter = createAction(
  '[Overview] Update ActivitiesDefinitionOverview Filter',
  props<{ params: ActivityGroupParameters }>()
);

export const updateActivitiesDefinitionOverviewPaginator = createAction(
  '[Overview] Update ActivitiesDefinitionOverview Paginator',
  props<{
    _pageSize: number;
    _page: number;
  }>()
);

export const updateActivitiesDefinitionOverviewSort = createAction(
  '[Overview] Update ActivitiesDefinitionOverview Sort',
  props<{
    _sortDirection: string;
    _sortBy: string;
  }>()
);




