import { Injectable } from '@angular/core';
import Utils from '@app/utils';
import { HttpClient } from '@angular/common/http';
import { ActivityDefectTaskWarranty } from '@shared/models/activity-defect-task-warranty';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ActivityDefinitionSummary } from '../../models/activity-definition-summary';
import { ActivityDefectTaskWarrantyParameters } from '@shared/models/activity-defect-task-warranty-parameters';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivityTask } from '@shared/models/activity-task';
import { ActivityGroupParameters } from '@shared/models/activity-definition-parameters';
import { TranslateService } from '@ngx-translate/core';
import { RsFormatPaginatedListRequestBody } from '@lib/rs-ngx';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
  }

  public getActivityDefinitions(
    filterParams: ActivityGroupParameters,
  ): Observable<RsFormatPaginatedListRequestBody<ActivityDefinitionSummary[]>> {

    const params = Utils.getHttpParams({
      ...filterParams,
      _userLanguage: this.translateService.currentLang,
    });

    return this.http.get<ActivityDefinitionSummary[]>(
      `${environment.apiUrl}/activity-definition`,
      { observe: 'response', params },
    ).pipe(rsFormatPaginatedListRequestBody());
  }

  public getActivityDefectTaskWarranties(
    filterParams: ActivityDefectTaskWarrantyParameters,
  ): Observable<RsFormatPaginatedListRequestBody<ActivityDefectTaskWarranty[]>> {

    const params = Utils.getHttpParams({
      ...filterParams,
      _userLanguage: this.translateService.currentLang,
    });

    return this.http
      .get<ActivityDefectTaskWarranty[]>(
        `${environment.apiUrl}/activity-defect-task-warranty`,
        { observe: 'response', params },
      ).pipe(rsFormatPaginatedListRequestBody());
  }

  public getActivityTasksBasedOnTaskWarranty(
    filterParams: ActivityDefectTaskWarrantyParameters,
  ): Observable<ActivityTask[]> {
    const params = Utils.getHttpParams(filterParams);
    return this.http
      .get<ActivityDefectTaskWarranty[]>(
        environment.apiUrl + '/activity-defect-task-warranty',
        { params },
      )
      .pipe(
        map((activitiesDefect) => {
          return _.uniqBy(
            activitiesDefect,
            (activity) => activity.activityDefinition.code && activity.activityTask.code,
          )
            ?.map(activity => activity.activityTask);
        }),
      );
  }
}
