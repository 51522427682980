import { createAction, props } from '@ngrx/store';
import {
  LeasingCompanyPartsReductionDetails
} from '@shared/models/leasing-company-parts-reduction/leasing-company-parts-reduction-details';
import { CodeDescription } from '@shared/models/code-description';

export const createActivityGroupReductionAdd = createAction(
  '[ActivityGroupReductionAdd] Create ActivityGroupReductionAdd',
);

export const createActivityGroupReductionAddSuccess = createAction(
  '[ActivityGroupReductionAdd] Create ActivityGroupReductionAdd Success',
  props<{ activityGroupReduction: LeasingCompanyPartsReductionDetails }>()
);

export const updateActivityGroup = createAction(
  '[ActivityGroupReductionAdd] Upladte ActivityGroup',
  props<{ activityGroup: CodeDescription }>()
);

export const updateSupplierGroup = createAction(
  '[ActivityGroupReductionAdd] Update SupplierGroup',
  props<{ supplierGroup: { code: string; description: string } }>()
);

export const updatePartsReductionPercentage = createAction(
  '[ActivityGroupReductionAdd] Update PartsReductionPercentage',
  props<{ partsReductionPercentage: number }>()
);
